<template>
    <v-card width="400px" max-width="90vw">
        <v-card-text>
            <div class="card-container">
                <v-img
                    width="50%"
                    :lazy-src="require('@/assets/images/logo_lazy.png')"
                    :src="require('@/assets/images/logo.png')"
                />
                <div class="card-content">
                    <div v-if="!emailSent">
                        <h3>
                            Melde dich jetzt für unseren Newsletter an und
                            erstelle kostenlos ein Portrait deines Lieblings. 🐶
                        </h3>
                        <p class="pt-3">
                            Aliquam quis nisl ut tellus rhoncus euismod. Ut sit
                            amet ante ligula. In eu ante in felis rhoncus
                            euismod. Nam ipsum arcu, congue quis tellus a,
                            placerat cursus libero. Aliquam sit amet leo
                            scelerisque, imperdiet lorem non, pretium velit.
                        </p>
                        <v-form ref="entryForm" @submit.prevent="submitHandler">
                            <v-text-field
                                type="email"
                                class="mt-8"
                                label="Deine Email"
                                v-model="email"
                                outlined
                                :rules="emailRules"
                            />
                            <v-checkbox
                                style="margin-top: -12px"
                                :rules="termsRules"
                                dense
                                v-model="isAcceptingTerms"
                            >
                                <div slot="label">
                                    Ich habe die
                                    <a href="/" target="_blank"
                                        >Nutzungsbedingungen</a
                                    >
                                    gelesen und bin mit ihnen Einverstanden.
                                </div>
                            </v-checkbox>
                            <v-btn
                                width="100%"
                                color="success"
                                x-large
                                type="submit"
                                >Anmelden</v-btn
                            >
                        </v-form>
                    </div>
                    <div v-else class="pa-6 pb-12 pt-12 text-center">
                        <AnimationLoop
                            animation-name="sent"
                            size="300px"
                            height="300px"
                            :loop="false"
                        />
                        Wir haben dir einen Link an die Email
                        <b>{{ email }}</b> gesendet. Bitte öffne die Mail und
                        klicke auf den Link um deine Email zu bestätigen.
                        Dadurch erhältst du Zugriff auf unseren
                        Portrait-Generator.
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import AnimationLoop from '@/components/AnimationLoop'
export default {
    name: 'RegisterNewsletter',
    components: { AnimationLoop },
    data() {
        return {
            email: '',
            emailRules: [],
            termsRules: [],
            isAcceptingTerms: false,
            emailSent: false,
        }
    },
    methods: {
        submitHandler() {
            this.emailRules = [
                (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    'Email Adresse ungültig.',
            ]
            this.termsRules = [(v) => v]
            let self = this
            setTimeout(function () {
                if (self.$refs.entryForm.validate()) {
                    //other codes
                    self.register()
                }
            }, 200)
        },
        register() {
            this.emailSent = true
        },
    },
}
</script>

<style lang="scss" scoped>
.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.card-content {
    margin-top: 24px;
}
</style>
