<template>
    <div>
        <PathLevel v-for="path in subPaths" :path="path" />
    </div>
</template>

<script>
import ImageTextCard from '@/components/TextImageCard'
import { mapGetters, mapMutations } from 'vuex'
import PathLevel from '@/components/PrintGenerator/components/TemplatePathChooser/components/PathLevel'
export default {
    name: 'TemplatePathChooser',
    components: { PathLevel, ImageTextCard },
    computed: {
        ...mapGetters(['templatePath']),
        subPaths() {
            const outerArray = []
            for (let i = 0; i < this.templatePath.length; i++) {
                const innerArray = []
                for (let k = 0; k <= i; k++) {
                    innerArray.push(this.templatePath[k])
                }
                outerArray.push(innerArray)
            }
            return outerArray
        },
    },
    methods: {
        ...mapMutations(['setCustomization']),
    },
}
</script>

<style scoped></style>
