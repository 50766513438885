<template>
    <div class="drawing-card" :style="`height: ${height};`">
        <v-hover v-slot="{ hover }">
            <div class="drawing-card__conatiner">
                <v-img
                    class="drawing-card__image"
                    min-height="100%"
                    max-height="100%"
                    :src="drawing.originalImage"
                />
                <v-btn
                    x-large
                    class="drawing-card__view-button"
                    :class="{ 'drawing-card__view-button--active': hover }"
                    fab
                    dark
                    @click="openDrawing"
                >
                    <v-icon>mdi-fullscreen</v-icon>
                </v-btn>
            </div>
        </v-hover>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'DrawingCard',
    props: {
        drawing: {
            type: Object,
            required: true,
        },
        height: {
            default: '600px',
        },
    },
    methods: {
        ...mapMutations(['setCurrentDrawing']),
        openDrawing() {
            this.setCurrentDrawing(this.drawing)
            this.$router.push('/result/' + this.drawing.uuid)
        },
    },
}
</script>

<style lang="scss" scoped>
.drawing-card {
    position: relative;
    height: 100%;
    max-height: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
    border-radius: 15px;

    &__conatiner {
        height: 100%;
        max-height: 100%;
    }

    &__image {
        border-radius: 15px;
    }

    &__view-button {
        z-index: 5;
        position: absolute !important;
        transform: scale(0);
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        transition: none;

        &--active {
            transform: scale(1);
            transition: transform 500ms ease;
        }
    }
}
</style>
