<template>
    <v-app>
        <v-main :class="{ 'prevent-scroll': !animationFinished }">
            <CheckInLoading
                style="z-index: 999"
                @animation-finished="animationFinished = true"
                v-if="isCheckingIn || !animationFinished"
            />
            <div v-if="key">
                <router-view />
            </div>
            <!--            <div v-else>-->
            <!--                <RegisterView />-->
            <!--            </div>-->
        </v-main>
        <MainFooter class="main-footer" />
        <AppSnackbars />
    </v-app>
</template>

<script>
import AppSnackbars from '@/components/AppSnackbars'
import { mapActions, mapGetters } from 'vuex'
import RegisterNewsletter from '@/components/RegisterNewsletter'
import RegisterView from '@/views/RegisterView'
import AnimationLoop from '@/components/AnimationLoop'
import MainFooter from '@/components/MainFooter'
import CheckInLoading from '@/components/CheckInLoading'
const { v4: uuidv4 } = require('uuid')

export default {
    name: 'App',
    components: {
        CheckInLoading,
        MainFooter,
        AnimationLoop,
        RegisterView,
        RegisterNewsletter,
        AppSnackbars,
    },
    computed: {
        ...mapGetters(['key']),
    },
    data: () => ({
        isCheckingIn: true,
        animationFinished: false,
    }),
    async created() {
        //TODO DELETE Before go live!
        // const storedApp = JSON.parse(localStorage.getItem('petdraw'))
        // console.log(storedApp)
        // if (storedApp) {
        //     const updatedApp = {
        //         ...storedApp,
        //         app: {
        //             ...storedApp.app,
        //             contingent: 50,
        //         },
        //     }
        //     localStorage.setItem('petdraw', JSON.stringify(updatedApp))
        // }
        if (!this.key) {
            await this.createKey()
        } else {
            this.animationFinished = true
        }
        this.isCheckingIn = false

        // TODO Overwork protection
        // if (!this.key || this.key !== this.$route.params.key) {
        //     await this.checkKey(this.$route.params.key)
        // } else if (this.key && this.$route.params.key) {
        //     this.$router.replace('/')
        // }
        // this.isCheckingIn = false
    },
    methods: {
        ...mapActions(['createKey']),
    },
}
</script>

<style lang="scss" scoped>
.v-main {
    //background-image: url('@/assets/images/mockups/bg_mock_1.jpg');
    //background-position: center top;
    //background-size: 100% auto;
    ////background: #00ffff;
    z-index: 2;
}

.prevent-scroll {
    max-height: 100vh;
    overflow: hidden;
}

.main-footer {
    z-index: 1;
}
</style>
