<template>
    <div class="horizontalScroller">
        <div class="horizontalScroller__content" ref="scroller">
            <div
                v-for="(card, index) in cards"
                :key="index"
                class="horizontalScroller__card"
                @click="handleClick"
            >
                <TextImageCard
                    :description="card.name"
                    :image="card.image"
                    :on-select="card.onClick"
                    :selected="selectedCard === card.id"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TextImageCard from '@/components/TextImageCard'
export default {
    name: 'HorizontalCardScroller',
    components: { TextImageCard },
    props: {
        cards: {
            type: Array,
            required: true,
        },
        selectedCard: {
            required: false,
        },
    },
    methods: {
        handleClick(event) {
            const cardElement = event.currentTarget
            const scroller = this.$refs.scroller
            const scrollerCenter = scroller.offsetWidth / 2
            const cardCenter = cardElement.offsetWidth / 2

            // Scroll the card to the middle
            scroller.scrollLeft =
                cardElement.offsetLeft - scrollerCenter + cardCenter
        },
    },
}
</script>

<style lang="scss" scoped>
.horizontalScroller {
    position: relative;
    width: 100%;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 10px;
        width: 20px;
        pointer-events: none;
        z-index: 2;

        background: linear-gradient(
            to right,
            white 0%,
            rgba(255, 255, 255, 0) 100%
        );
    }

    &::after {
        right: 0;
        transform: scaleX(-1);
    }

    &__content {
        display: flex;
        overflow-x: auto;
        white-space: nowrap;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            height: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        scrollbar-width: thin;
        scrollbar-color: #888 #f1f1f1;
    }

    &__card {
        width: 37.5%;
        margin-right: 10px;
        padding: 6px 12px 24px 12px;
    }
}
</style>
