<template>
    <div style="width: 500px">
        <!--        <ImageMockup-->
        <!--            :mockup-img-src="-->
        <!--                require('@/assets/images/sandbox/poster_mockup.png')-->
        <!--            "-->
        <!--            :product-image-corners="[-->
        <!--                [0.3687368737, 0.0888088809],-->
        <!--                [0.750975, 0.0657065707],-->
        <!--                [0.7557755776, 0.7356735674],-->
        <!--                [0.3717371737, 0.7248735374],-->
        <!--            ]"-->
        <!--        >-->
        <!--            <PrintPreview :use-aspect-ratio="false" />-->
        <!--        </ImageMockup>-->
        <ImageMockup
            :mockup-img-src="
                require('@/assets/images/mockups/print/print_mockup.png')
            "
            :product-image-corners="[
                [0.3262, 0.1745],
                [0.873, 0.1752],
                [0.8446, 0.7071],
                [0.2971, 0.6941],
            ]"
        >
            <PrintPreview :use-aspect-ratio="false" />
        </ImageMockup>
    </div>
</template>

<script>
import ImageMockup from '@/components/OrderResult/MockupImageWrapper'
import PrintPreview from '@/components/PrintGenerator/components/PrintPreview'
export default {
    name: 'SandboxView',
    components: { PrintPreview, ImageMockup },
}
</script>

<style scoped></style>
