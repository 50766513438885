<template>
    <div
        class="drawing-generator__container"
        :style="`height: ${
            generatorInputImage && !isGenerating ? 'fit-content' : height
        };`"
    >
        <div class="drawing-generator__content" v-if="!isGenerating">
            <v-hover v-if="!generatorInputImage" v-slot="{ hover }">
                <v-sheet
                    height="100%"
                    @dragover="showDropZone = true"
                    @dragleave="showDropZone = false"
                    rounded
                    class="drop-sheet all-shaped"
                    color="#cfcccc"
                    @drop.prevent="dropFile"
                    @dragenter.prevent
                    @dragover.prevent
                >
                    <div
                        class="drop-sheet__content"
                        @click="$refs.filePickerField.click()"
                    >
                        <v-icon
                            :class="
                                hover || showDropZone
                                    ? 'upload-icon upload-icon--hover'
                                    : 'upload-icon'
                            "
                            >mdi-upload</v-icon
                        >
                        <h3
                            :class="
                                hover
                                    ? 'hover-description hover-description--hover'
                                    : 'hover-description'
                            "
                        >
                            Klicken oder Datei reinziehen
                        </h3>
                        <input
                            ref="filePickerField"
                            type="file"
                            accept="image/*"
                            @change="launchCropper"
                            hidden
                        />
                        <ImageCropperDialog
                            ref="cropperDialog"
                            :chosenImage="chosenImage"
                            @onReset="$refs.filePickerField.value = null"
                            @onCrop="
                                (croppedImage) => {
                                    generatorInputImage = croppedImage
                                }
                            "
                        />
                    </div>
                </v-sheet>
            </v-hover>
            <div class="drawing-generator__image-content" v-else>
                <v-img
                    class="all-shaped"
                    :src="generatorInputImage"
                    style="border-radius: 15px"
                />
                <v-btn
                    class="delete-image-btn"
                    small
                    absolute
                    fab
                    @click="
                        () => {
                            generatorInputImage = null
                            showDropZone = false
                        }
                    "
                >
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </div>
            <div v-if="generatorInputImage" class="drawing-generator__controls">
                <v-btn
                    @click="generateDrawing"
                    x-large
                    :dark="!!generatorInputImage"
                    width="100%"
                    class="bottom-shaped"
                    :disabled="!generatorInputImage"
                    >Zeichne Mein Liebling</v-btn
                >
                <v-progress-linear
                    v-if="isGenerating"
                    indeterminate
                ></v-progress-linear>
            </div>
        </div>
        <div v-else class="waiting-animation">
            <AnimationLoop
                height="400px"
                size="350px"
                animation-name="generate_drawing"
            />
            <div class="waiting-animation__phrase" :class="waitingPhraseClass">
                {{ waitingPhrase }}
            </div>
        </div>
        <CreateAccountDialog
            :show="showCreateAccountDialog"
            :on-close="
                () => {
                    showCreateAccountDialog = false
                }
            "
        />
    </div>
</template>

<script>
import ImageTextCard from '@/components/TextImageCard'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../firebase'
import { delayMs, encodeBase64 } from '@/utils/utils'
import AnimationLoop from '@/components/AnimationLoop'
import ImageCropperDialog from '@/components/ImageCropperDialog'
import { mapGetters, mapMutations } from 'vuex'
import { EventBus, events } from '@/eventbus'
import CreateAccountDialog from '@/components/CreateAccountDialog'
import { analyticEvents, triggerEvent } from '@/utils/analytics'

export default {
    name: 'GeneratorCard',
    components: {
        CreateAccountDialog,
        ImageCropperDialog,
        AnimationLoop,
        ImageTextCard,
    },
    props: {
        height: {
            default: '600px',
        },
    },
    data() {
        return {
            chosenImage: null,
            chosenFile: null,
            isGenerating: false,
            generatorInputImage: null,
            showDropZone: false,
            showCreateAccountDialog: false,
            waitingPhrase: '',
            waitingPhraseClass: '',
        }
    },
    computed: {
        ...mapGetters(['contingent', 'key']),
    },
    created() {
        // this.isGenerating = true
        // this.generateWaitingPhrases()
    },
    methods: {
        ...mapMutations([
            'addDrawing',
            'setCurrentDrawing',
            'decreaseContingent',
        ]),
        async launchCropper(event) {
            if (this.contingent === 0) {
                this.showCreateAccountDialog = true
                return
            }
            if (!event) return
            const file = event.target.files[0]
            this.chosenFile = file
            this.chosenImage = await this.toBase64(file)
            this.$refs.cropperDialog.initCropper(file.type)
            triggerEvent(analyticEvents.SELECT_IMAGE, {
                fileName: file.name,
                fileSize: file.size,
                fileType: file.type,
            })
        },
        async toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        },
        async generateWaitingPhrases() {
            const waitingPhrases = [
                'Dein Portrait wird gezeichnet. Wir sind gleich zurück und holen uns kurz einen Kaffee',
                'Nichts geht über eine gute Tasse Kaffee, außer vielleicht das Warten auf Dein Portrait.',
                'Wir arbeiten hart daran, dein Portrait zum Leben zu erwecken.',
                'Upps wir haben den Kaffee verschüttet, sind gleich zurück.',
                'Wusstest du, dass das Warten auf dein Portrait genauso lange dauert wie das Kochen eines perfekten Eies?',
            ]

            for (const index in waitingPhrases) {
                this.waitingPhrase = waitingPhrases[index]
                this.waitingPhraseClass = 'waiting-animation__phrase--grow'
                await delayMs(5000)
                this.waitingPhraseClass = 'waiting-animation__phrase--shrink'
                await delayMs(1000)
            }
        },
        async generateDrawing() {
            this.isGenerating = true
            triggerEvent(analyticEvents.DRAW_IMAGE, {
                fileName: this.chosenFile.name,
                fileSize: this.chosenFile.size,
                fileType: this.chosenFile.type,
            })
            this.generateWaitingPhrases()
            const generateDrawing = httpsCallable(
                functions,
                'generateDrawingV2'
            )

            const res = await generateDrawing({
                target_img: encodeBase64(this.generatorInputImage),
                key: this.key,
            })

            if (!res.data.success) {
                EventBus.$emit(events.SNACKBAR, {
                    message: this.$t(`app.error.code.${res.data.errorCode}`),
                    type: 'error',
                })
                this.isGenerating = false
                return
            }

            this.decreaseContingent()

            const drawing = {
                uuid: res.data.data.uuid,
                originalImage: res.data.data.original_image,
                resultImage: res.data.data.result_image,
                resultImageW: res.data.data.result_image_w,
                cutoutMask: '',
            }

            this.addDrawing(drawing)
            this.setCurrentDrawing(drawing)
            this.isGenerating = false
            this.$router.push('/result/' + drawing.uuid)
        },
        async dropFile(e) {
            e.preventDefault()
            if (this.contingent === 0) {
                this.showCreateAccountDialog = true
                return
            }

            let droppedFiles = e.dataTransfer
                ? e.dataTransfer.files
                : e.target.files
            if (droppedFiles) {
                const file = droppedFiles[0]
                this.chosenImage = await this.toBase64(file)
                await this.$refs.cropperDialog.initCropper(file.type)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.drawing-generator {
    &__container {
        overflow: hidden;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.25) 0 0.0625em 0.0625em,
            rgba(0, 0, 0, 0.25) 0 0.125em 0.5em,
            rgba(255, 255, 255, 0.1) 0 0 0 1px inset;
    }

    &__content {
        width: 100%;
        height: 100%;
    }

    &__controls {
        position: absolute;
        width: 100%;
        bottom: 0;
    }

    &__image-content {
        height: 100%;
    }
}

.waiting-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;

    &__phrase {
        position: absolute;
        bottom: 36px;
        text-align: center;
        opacity: 1;
        font-size: 24px;
        transform: scale(1.2);
        transition: opacity 0.8s ease-out, transform 1s ease-out;

        &--shrink {
            opacity: 0;
            transform: scale(1.2);
        }

        &--grow {
            opacity: 1;
            transform: scale(0.8);
        }
    }
}

.upload-icon {
    font-size: 133px;
    transition: all 500ms ease;
    color: #fff;

    &--hover {
        font-size: 166px;
        color: #000000;
    }
}

.delete-image-btn {
    top: 18px;
    right: 18px;
    box-shadow: rgb(236, 201, 214) 3px 3px 6px 0 inset,
        rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    background-color: #fff;
}

.all-shaped {
    border-radius: 15px !important;
}

.bottom-shaped {
    border-radius: 0 0 15px 15px !important;
}

.drop-sheet {
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.hover-description {
    color: #fff;
    transform: scaleY(0);
    opacity: 0;
    transition: all 100ms ease;

    &--hover {
        transform: scaleY(1);
        color: #000000;
        opacity: 1;
        transition: all 100ms ease;
    }
}

.result-image {
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
}
</style>
