<template>
    <div v-resize="computeTextSize" class="printPreview" ref="printPreview">
        <CroppableImageConatiner
            :disabled="disabled"
            :key="customization.useClippedImage"
            v-if="!customization.useClippedImage"
            :style="`
                    bottom: ${templateData.drawingContainer.bottom}%;
                    width: ${templateData.drawingContainer.width}%;
                    height: ${templateData.drawingContainer.height}%;`"
            class="printPreview__drawing"
            :image="
                customization.croppedImage ||
                currentDrawing?.resultImageCompressed
            "
            :original-image="currentDrawing?.resultImageCompressed"
            :view-mode="3"
            @onCrop="cropImage"
            @onCropReset="cropImage"
        />
        <CroppableImageConatiner
            :disabled="disabled"
            :key="customization.useClippedImage"
            v-else-if="
                customization.useClippedImage && customization.clippedImage
            "
            class="printPreview__drawing"
            :style="`
                    bottom: ${templateData.drawingContainer.bottom}%;
                    width: ${templateData.drawingContainer.width}%;
                    height: ${templateData.drawingContainer.height}%;
                    `"
            :image="customization.croppedImage || customization.clippedImage"
            :original-image="customization.clippedImage"
            :view-mode="0"
            @onCrop="cropImage"
            @onCropReset="cropImage"
        />
        <div class="printPreview__cutout-loading" v-else>
            <v-progress-circular indeterminate size="30" />
            <div class="printPreview__loading-text">
                Foto wird ausgeschnitten...
            </div>
        </div>
        <div
            :style="`
                    padding-top: ${
                        useAspectRatio ? templateData.aspectRatio : 100
                    }%;
                    background-color: ${
                        customization.useClippedImage
                            ? '#fff'
                            : customization.backgroundColor
                    };
                `"
            class="printPreview__template"
        />
        <img
            :key="templateData.templateFile"
            v-if="templateData.templateFile || customTemplate"
            :src="
                customTemplate?.templateFileLazy ||
                templateData.templateFileLazy
            "
            class="printPreview__template-file"
            height="100%"
            width="100%"
        />
        <div
            class="printPreview__textContainer"
            :key="customization.textSize + customization.textFont"
            :style="`bottom: ${templateData.text.bottom}%`"
        >
            <div>
                <div
                    :style="`
                                    color: ${customization.textColor};
                                    font-size: ${computedTextSize}px;
                                    font-family: '${customization.textFont}';
                                `"
                    class="printPreview__text"
                >
                    {{ customization.text }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CroppableImageConatiner from '@/components/PrintGenerator/components/CroppableImageConatiner'
import { mapGetters, mapMutations } from 'vuex'
import { getTemplate } from '@/content/products'
import AnimationLoop from '@/components/AnimationLoop'
export default {
    name: 'PrintPreview',
    components: { AnimationLoop, CroppableImageConatiner },
    data() {
        return {
            computedTextSize: 0,
        }
    },
    computed: {
        ...mapGetters(['currentDrawing', 'customization', 'templatePath']),
        templateData() {
            return getTemplate(this.templatePath)
        },
        textSize() {
            return this.customization.textSize
        },
    },
    watch: {
        textSize: {
            immediate: true,
            handler() {
                this.computeTextSize()
            },
        },
    },
    props: {
        disabled: {
            default: false,
        },
        useAspectRatio: {
            default: true,
        },
        customTemplate: {
            required: false,
        },
    },
    mounted() {
        const observer = new MutationObserver(this.computeTextSize)
        observer.observe(this.$refs.printPreview, {
            attributes: true,
            childList: true,
            characterData: true,
            subtree: true,
        })
    },
    beforeUnmount() {
        const observer = new MutationObserver(this.computeTextSize)
        observer.disconnect()
    },
    methods: {
        ...mapMutations(['setCroppedImage']),
        cropImage(data) {
            this.setCroppedImage(data)
        },
        computeTextSize() {
            const offsetHeight = this.$refs.printPreview?.offsetHeight

            if (offsetHeight) {
                this.computedTextSize =
                    (offsetHeight * this.customization.textSize) / 100
            } else {
                setTimeout(this.computeTextSize, 100)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.printPreview {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -20px;
    overflow: hidden;

    &__animation-container {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 4;
        pointer-events: none;
        margin-top: 60px;
        margin-left: 30px;
    }

    &__template {
        z-index: 1;
        background-color: #fff;
        width: 100%;
        height: 100%;
    }

    &__template-file {
        z-index: 3;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__drawing {
        position: absolute;
        z-index: 3;
    }

    &__cutout-loading {
        z-index: 10;
        position: absolute;
        top: calc(50% - 90px);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__loading-text {
        margin-top: 4px;
        font-size: 16px;
        text-align: center;
        color: #000;
    }

    &__textContainer {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-align: center;
        z-index: 3;
        pointer-events: none;
        height: fit-content;
        transform: translateY(50%);
    }

    &__text {
        white-space: nowrap;
    }
}
</style>
