<template>
    <div
        class="check-in-loading-container"
        :class="{ 'check-in-loading-container--fade-out': animationFinished }"
    >
        <AnimationLoop animation-name="generate_drawing" />
        <div class="loading-description">
            <h1 v-if="!typeLogo">
                Wir malen
                <br class="d-sm-none" />
                <VueTyper
                    v-if="nextToBeTyped"
                    style="font-family: 'Titan One'"
                    erase-style="backspace"
                    @typed="onTyped"
                    :text="nextToBeTyped"
                />
            </h1>
            <h1 v-else>
                Wir
                <VueTyper
                    style="font-family: 'Titan One'"
                    erase-style="backspace"
                    @typed="onTypedLogo"
                    text="Malen.es"
                />
            </h1>
        </div>
    </div>
</template>

<script>
import { VueTyper } from 'vue-typer'
import AnimationLoop from '@/components/AnimationLoop'
import { delayMs } from '@/utils/utils'
export default {
    name: 'CheckInLoading',
    components: { AnimationLoop, VueTyper },
    data() {
        return {
            typeLogo: false,
            animationFinished: false,
            typedIndex: 0,
            nextToBeTyped: '',
            toBeTyped: ['deinen Hund', 'deine Katze', 'dein Pferd'],
        }
    },
    methods: {
        async onTyped() {
            if (this.typedIndex === this.toBeTyped.length) {
                await delayMs(1500)
                this.typeLogo = true
            }
            await delayMs(this.typedIndex === 0 ? 400 : 1500)
            this.nextToBeTyped = this.toBeTyped[this.typedIndex]
            this.typedIndex = this.typedIndex + 1
        },
        async onTypedLogo() {
            await delayMs(1500)
            this.animationFinished = true
            await delayMs(500)
            return this.$emit('animation-finished')
        },
    },
    mounted() {
        this.onTyped()
    },
}
</script>

<style lang="scss" scoped>
.check-in-loading-container {
    background-color: #fff;
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transform: scale(1);
    transition: all 500ms ease-out;

    &--fade-out {
        opacity: 0;
        transform: scale(3);
    }
}
.loading-description {
    text-align: center;
    position: absolute;
    bottom: 30%;
    margin: 0 auto;
}
</style>
