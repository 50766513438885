<template>
    <div class="d-flex justify-center flex-column align-center">
        <div class="image-container">
            <v-img
                class="result-image"
                width="80vh"
                max-width="80vw"
                :src="drawing?.resultImage"
                :style="{ opacity: effectStrength }"
            />
            <v-img
                class="original-image"
                width="80vh"
                max-width="80vw"
                :src="drawing?.originalImage"
            />
        </div>
        <div class="effect-slider-container">
            <v-slider
                label="Effekt Stärke"
                class="effect-slider"
                v-model="effectStrengthPercentage"
                step="10"
                dense
                hide-details
                thumb-label="always"
            ></v-slider>
        </div>
        <div
            style="
                display: flex;
                margin-top: 30px;
                width: 100%;
                justify-content: center;
                margin-bottom: 30px;
                gap: 12px;
            "
        >
            <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" dark to="/"
                        ><v-icon>mdi-arrow-left</v-icon></v-btn
                    >
                </template>
                <span>Zurück</span>
            </v-tooltip>
            <!--            <v-btn-->
            <!--                :loading="isDownloading"-->
            <!--                :disabled="isDownloading"-->
            <!--                :dark="!isDownloading"-->
            <!--                @click="downloadDrawing"-->
            <!--                ><v-icon>mdi-download</v-icon></v-btn-->
            <!--            >-->
            <v-btn
                :disabled="savingImage"
                :loading="savingImage"
                @click="goToPrint"
                color="success"
                >Editor <v-icon>mdi-chevron-right</v-icon></v-btn
            >
        </div>
    </div>
</template>

<script>
import ImageTextCard from '@/components/TextImageCard'
import { downloadImageFromUrl, mergeImagesWithOpacity } from '@/utils/utils'
import AnimationLoop from '@/components/AnimationLoop'
import ImageCropperDialog from '@/components/ImageCropperDialog'
import { mapActions, mapMutations } from 'vuex'
import { analyticEvents, triggerEvent } from '@/utils/analytics'

export default {
    name: 'DrawingResult',
    components: { ImageCropperDialog, AnimationLoop, ImageTextCard },
    data() {
        return {
            isDownloading: false,
            savingImage: false,
            chosenImage: null,
            drawing: null,
            isGenerating: false,
            generatedArtUrlWatermarked: '', //'https://iili.io/HcUdsb1.png',
            generatorInputImage: null,
            showDropZone: false,
            effectStrengthPercentage: 90,
        }
    },
    computed: {
        effectStrength: {
            get() {
                return this.effectStrengthPercentage / 100
            },
        },
    },
    props: {
        drawingUuid: {
            type: String,
            required: true,
        },
    },
    async created() {
        this.drawing = await this.getDrawing(this.drawingUuid)
    },
    methods: {
        ...mapMutations(['setCurrentDrawing', 'setEffectStrength']),
        ...mapActions(['getDrawing', 'resetCustomization']),
        async downloadDrawing() {
            this.isDownloading = true
            const mergedImage = await mergeImagesWithOpacity(
                this.drawing.resultImage,
                this.drawing.originalImage,
                this.effectStrength,
                null
            )

            downloadImageFromUrl(mergedImage, 'Malen.es-' + this.drawingUuid)
            this.isDownloading = false
        },
        async applyEffectStrength() {
            this.savingImage = true
            const mergedImage = await mergeImagesWithOpacity(
                this.drawing.resultImage,
                this.drawing.originalImage,
                this.effectStrength,
                0.6
            )
            this.savingImage = false
            return mergedImage
        },
        async goToPrint() {
            await this.resetCustomization()
            const resultImageCompressed = await this.applyEffectStrength()
            await this.setCurrentDrawing({
                ...this.drawing,
                resultImageCompressed,
            })
            await this.setEffectStrength(this.effectStrength)
            triggerEvent(analyticEvents.OPEN_EDITOR, {
                effect_strength: this.effectStrength,
            })
            this.$router.push(`/print/${this.drawing.uuid}`)
        },
    },
}
</script>

<style lang="scss" scoped>
.image-container {
    position: relative;
    width: 80vh;
    max-width: 80vw;
    background: none;
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 50px 0;
    border-radius: 10px;
    overflow: hidden;
}

.result-image {
    z-index: 2;
}

.original-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.effect-slider-container {
    width: 80vh;
    max-width: 80vw;
}

.effect-slider {
    margin-top: 46px;
    background: none;
}
</style>
