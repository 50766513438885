<template>
    <v-container>
        <div class="register-container">
            <RegisterNewsletter />
        </div>
    </v-container>
</template>

<script>
import RegisterNewsletter from '@/components/RegisterNewsletter'
export default {
    name: 'RegisterView',
    components: { RegisterNewsletter },
}
</script>

<style lang="scss" scoped>
.register-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
