import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../firebase'
import { EventBus, events } from '@/eventbus'
import i18n from '@/i18n'

const defaultState = {
    myDrawings: [],
    isGenerating: false,
    showTips: true,
    key: '',
    contingent: 0,
}

const getters = {
    myDrawings: (state) => state.myDrawings,
    key: (state) => state.key,
    contingent: (state) => state.contingent,
    showTips: (state) => state.showTips,
}

const actions = {
    async getDrawing({ getters }, drawingUuid) {
        return getters.myDrawings.find(
            (drawing) => drawing.uuid === drawingUuid
        )
    },
    async createKey({ commit }) {
        const createKey = httpsCallable(functions, 'createKey')
        const res = await createKey()
        if (res.data.success) {
            commit('setKey', res.data.key)
            commit('setContingent', res.data.contingent)
        } else {
            EventBus.$emit(events.SNACKBAR, {
                message: i18n.t('app.error.code.' + res.data.errorCode),
                type: 'error',
            })
        }
    },
    async checkKey({ commit }, key) {
        if (key) {
            const checkIn = httpsCallable(functions, 'checkIn')
            const res = await checkIn({
                key,
            })
            if (res.data.success) {
                commit('setKey', key)
                commit('setContingent', res.data.data.contingent)
            }
        }
    },
}

const mutations = {
    addDrawing: (state, drawing) => state.myDrawings.push(drawing),
    setShowTips: (state, bool) => (state.showTips = bool),
    setIsGenerating: (state, bool) => (state.isGenerating = bool),
    updateDrawing: (state, drawing) => {
        const index = state.myDrawings.findIndex(
            (existingDrawing) => existingDrawing.uuid === drawing.uuid
        )
        Object.assign(state.myDrawings[index], { ...drawing })
    },
    setKey: (state, key) => (state.key = key),
    setContingent: (state, contingent) => (state.contingent = contingent),
    decreaseContingent: (state) => (state.contingent = state.contingent - 1),
}

const state = window.sessionStorage[process.env.VUE_APP_NAME]
    ? JSON.parse(window.sessionStorage[process.env.VUE_APP_NAME]).app
    : Object.assign({}, defaultState)

export default {
    state,
    getters,
    actions,
    mutations,
}
