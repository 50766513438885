import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '../views/MainView.vue'
import PrintView from '@/views/PrintView'
import ResultView from '@/views/ResultView'
import SandboxView from '@/views/SandboxView'
import OrderView from '@/views/OrderView'
import OrderResults from '@/components/OrderResult/OrderResults'
import OrderSuccess from '@/components/OrderResult/OrderSuccess'

Vue.use(VueRouter)

const routes = [
    {
        path: '/sand',
        name: 'sandbox',
        component: SandboxView,
    },
    {
        path: '/order/:id',
        name: 'order',
        component: OrderView,
        children: [
            {
                path: '/',
                name: 'order-results',
                component: OrderResults,
            },
            {
                path: 'success',
                name: 'order-success',
                component: OrderSuccess,
            },
        ],
    },
    {
        path: '/',
        name: 'home',
        component: MainView,
        children: [
            {
                path: '/:key',
                name: 'home-check-in',
                component: MainView,
            },
        ],
    },
    {
        path: '/print/:id',
        name: 'print',
        component: PrintView,
    },
    {
        path: '/result/:id',
        name: 'result',
        component: ResultView,
    },
]

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        // return desired position
        return { x: 0, y: 0 }
    },
    routes,
})

export default router
