var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"drawing-generator__container",style:(`height: ${
        _vm.generatorInputImage && !_vm.isGenerating ? 'fit-content' : _vm.height
    };`)},[(!_vm.isGenerating)?_c('div',{staticClass:"drawing-generator__content"},[(!_vm.generatorInputImage)?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-sheet',{staticClass:"drop-sheet all-shaped",attrs:{"height":"100%","rounded":"","color":"#cfcccc"},on:{"dragover":[function($event){_vm.showDropZone = true},function($event){$event.preventDefault();}],"dragleave":function($event){_vm.showDropZone = false},"drop":function($event){$event.preventDefault();return _vm.dropFile.apply(null, arguments)},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"drop-sheet__content",on:{"click":function($event){return _vm.$refs.filePickerField.click()}}},[_c('v-icon',{class:hover || _vm.showDropZone
                                ? 'upload-icon upload-icon--hover'
                                : 'upload-icon'},[_vm._v("mdi-upload")]),_c('h3',{class:hover
                                ? 'hover-description hover-description--hover'
                                : 'hover-description'},[_vm._v(" Klicken oder Datei reinziehen ")]),_c('input',{ref:"filePickerField",attrs:{"type":"file","accept":"image/*","hidden":""},on:{"change":_vm.launchCropper}}),_c('ImageCropperDialog',{ref:"cropperDialog",attrs:{"chosenImage":_vm.chosenImage},on:{"onReset":function($event){_vm.$refs.filePickerField.value = null},"onCrop":(croppedImage) => {
                                _vm.generatorInputImage = croppedImage
                            }}})],1)])]}}],null,false,3557286178)}):_c('div',{staticClass:"drawing-generator__image-content"},[_c('v-img',{staticClass:"all-shaped",staticStyle:{"border-radius":"15px"},attrs:{"src":_vm.generatorInputImage}}),_c('v-btn',{staticClass:"delete-image-btn",attrs:{"small":"","absolute":"","fab":""},on:{"click":() => {
                        _vm.generatorInputImage = null
                        _vm.showDropZone = false
                    }}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)],1),(_vm.generatorInputImage)?_c('div',{staticClass:"drawing-generator__controls"},[_c('v-btn',{staticClass:"bottom-shaped",attrs:{"x-large":"","dark":!!_vm.generatorInputImage,"width":"100%","disabled":!_vm.generatorInputImage},on:{"click":_vm.generateDrawing}},[_vm._v("Zeichne Mein Liebling")]),(_vm.isGenerating)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e()],1):_vm._e()],1):_c('div',{staticClass:"waiting-animation"},[_c('AnimationLoop',{attrs:{"height":"400px","size":"350px","animation-name":"generate_drawing"}}),_c('div',{staticClass:"waiting-animation__phrase",class:_vm.waitingPhraseClass},[_vm._v(" "+_vm._s(_vm.waitingPhrase)+" ")])],1),_c('CreateAccountDialog',{attrs:{"show":_vm.showCreateAccountDialog,"on-close":() => {
                _vm.showCreateAccountDialog = false
            }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }