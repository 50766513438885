var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cic"},[(!_vm.loading && !_vm.disabled)?_c('div',{staticClass:"cic__animation-container"},[(
                ((_vm.currentTip && _vm.isEditMode) || _vm.currentTip === 'click') &&
                _vm.showAllTips
            )?_c('AnimationLoop',{key:_vm.currentTip,attrs:{"height":"130px","size":"130px","animation-name":_vm.currentTip,"delay-millis":1200}}):_vm._e()],1):_vm._e(),(!_vm.isEditMode)?_c('v-hover',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('div',{staticClass:"cic__image-container"},[_c('v-img',{staticClass:"cic__image-container",style:(`
                    mask-image: ${_vm.maskPathCss};
                    background: ${_vm.backgroundCss};
                `),attrs:{"src":_vm.image,"height":"100%"}}),(!_vm.isEditMode)?_c('v-btn',{staticClass:"cic__edit-button",class:{ 'cic__edit-button--active': hover },attrs:{"x-large":"","fab":"","dark":""},on:{"click":_vm.editImage}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}}],null,false,3456096437)}):_vm._e(),(_vm.isEditMode)?_c('vue-cropper',{ref:"cropper",staticClass:"cic__image-container",style:(`
            visibility: ${_vm.loading ? 'hidden' : 'visible'};
            mask-image: ${_vm.maskPathCss};
            background: ${_vm.backgroundCss};
        }`),attrs:{"guides":true,"background":false,"view-mode":_vm.viewMode,"drag-mode":"move","src":_vm.originalImage,"alt":"Image not available"},on:{"ready":_vm.initCropper,"zoom":() => _vm.handleTips('zoom'),"cropmove":() => _vm.handleTips('move')}}):_vm._e(),(_vm.loading && _vm.isEditMode)?_c('v-progress-circular',{staticClass:"cic__loading",attrs:{"indeterminate":"","size":"30"}}):_vm._e(),(_vm.isEditMode && !_vm.loading)?_c('v-btn',{staticClass:"cic__cancel-btn",attrs:{"small":"","color":"error","fab":"","dark":""},on:{"click":_vm.resetCrop}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.isEditMode && !_vm.loading)?_c('v-btn',{staticClass:"cic__save-btn",attrs:{"small":"","color":"success","fab":"","dark":""},on:{"click":_vm.crop}},[_c('v-icon',[_vm._v("mdi-check-bold")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }