<template>
    <div ref="printGeneratorContainer" v-resize="handleResize" class="mt-12">
        <v-row style="min-height: 500px">
            <v-tabs :value="tabIndex" v-if="productsArray.length > 1">
                <v-tab
                    v-for="product in productsArray"
                    :key="product.key"
                    @click="() => changeProduct(product.key)"
                >
                    <v-badge
                        v-if="product.badge"
                        :offset-x="20"
                        :offset-y="0"
                        :content="product.badge"
                        color="success"
                    >
                        {{ product.label }}
                    </v-badge>
                    <div v-else>
                        {{ product.label }}
                    </div>
                </v-tab>
            </v-tabs>
            <v-col cols="12" sm="6">
                <div
                    class="print-preview-container"
                    ref="previewContainer"
                    v-observe-visibility="{
                        callback: visibilityChanged,
                        intersection: {
                            threshold: 0.5,
                        },
                    }"
                >
                    <PrintPreview />
                </div>
            </v-col>
            <v-col cols="12" sm="6">
                <v-expansion-panels v-model="expansionPanel" accordion>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            >Vorlage Wählen</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                            <TemplatePathChooser />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            >Maske Wählen</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                            <div :key="templatePath.toString()">
                                <HorizontalCardScroller
                                    :selected-card="customization.maskPath"
                                    :cards="maskCards"
                                />
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            >Bild Freistellen</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="6" md="6" lg="4">
                                    <TextImageCard
                                        description="Original Hintergrund"
                                        :selected="!useClippedImage"
                                        :image="
                                            require(`@/assets/images/printGenerator/horizontal_inset.jpg`)
                                        "
                                        :lazy="
                                            require(`@/assets/images/printGenerator/horizontal_inset_lazy.jpg`)
                                        "
                                        :on-select="
                                            () => {
                                                useClippedImage = false
                                            }
                                        "
                                    />
                                </v-col>
                                <v-col cols="6" md="6" lg="4">
                                    <TextImageCard
                                        description="Eigener Hintergrund"
                                        :image="
                                            require(`@/assets/images/printGenerator/horizontal_cutout.jpg`)
                                        "
                                        :lazy="
                                            require(`@/assets/images/printGenerator/horizontal_cutout_lazy.jpg`)
                                        "
                                        :selected="useClippedImage"
                                        :on-select="
                                            () => {
                                                useClippedImage = true
                                            }
                                        "
                                    />
                                </v-col>
                            </v-row>
                            <v-row v-if="useClippedImage">
                                <v-col cols="12">
                                    <p>Hintergrund wählen</p>
                                    <v-divider />
                                    <HorizontalCardScroller
                                        class="mt-6"
                                        :selected-card="
                                            customization.backgroundTexturePath
                                        "
                                        :cards="backgroundTextureCards"
                                    />
                                </v-col>
                            </v-row>
                            <v-row
                                v-if="
                                    useClippedImage &&
                                    !customization.backgroundTexturePath
                                "
                            >
                                <v-col cols="12">
                                    <p>Farbe wählen</p>
                                    <v-divider />
                                    <v-color-picker
                                        class="mt-6"
                                        v-model="backgroundColor"
                                        dot-size="40"
                                        :hide-sliders="
                                            hideColorPickerSliders(
                                                backgroundColor
                                            )
                                        "
                                        hide-canvas
                                        canvas-height="50px"
                                        hide-inputs
                                        hide-mode-switch
                                        show-swatches
                                        swatches-max-height="400"
                                        width="100%"
                                    ></v-color-picker>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            >Personalisierungen</v-expansion-panel-header
                        >
                        <v-expansion-panel-content>
                            <v-text-field
                                label="Name Deines Lieblings"
                                v-model="text"
                                outlined
                            ></v-text-field>
                            <v-select
                                v-model="textFont"
                                outlined
                                :items="allFonts"
                                item-value="value"
                                label="Schriftart"
                            >
                                <template #item="{ item }">
                                    <div :style="{ fontFamily: item.value }">
                                        {{ text || item.label }}
                                    </div>
                                </template>

                                <template #selection="{ item }">
                                    <div :style="{ fontFamily: item.value }">
                                        {{ item.label }}
                                    </div>
                                </template>
                            </v-select>
                            <v-slider
                                label="Schriftgröße"
                                hint="Im a hint"
                                :max="currentTemplate?.text?.maxSize"
                                :min="currentTemplate?.text?.minSize"
                                step="0.1"
                                v-model="textSize"
                            ></v-slider>
                            <div class="text-color-picker">
                                <v-color-picker
                                    v-model="textColor"
                                    dot-size="40"
                                    :hide-sliders="
                                        hideColorPickerSliders(textColor)
                                    "
                                    hide-canvas
                                    canvas-height="50px"
                                    hide-inputs
                                    hide-mode-switch
                                    show-swatches
                                    swatches-max-height="400"
                                    width="100%"
                                ></v-color-picker>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn
                    @click="orderResult"
                    x-large
                    color="success"
                    width="100%"
                    class="mt-3"
                    >Weiter</v-btn
                >
            </v-col>
        </v-row>
        <v-btn
            rounded
            class="go-to-preview-btn"
            :class="{
                'go-to-preview-btn__show':
                    showFixedToPreviewButton || lastScrollPosition,
            }"
            fixed
            bottom
            dark
            right
            @click="scrollTo"
        >
            {{ lastScrollPosition ? 'Zurück' : 'Zur Vorschau' }}
            <v-icon class="ml-1">{{
                lastScrollPosition
                    ? 'mdi-arrow-down-thick'
                    : 'mdi-arrow-up-thick'
            }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
import ImageTextCard from '@/components/TextImageCard'
import PrintPreview from '@/components/PrintGenerator/components/PrintPreview'
import OrderResults from '@/components/OrderResult/OrderResults'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../firebase'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { getTemplate, products, productsArray } from '@/content/products'
import TemplatePathChooser from '@/components/PrintGenerator/components/TemplatePathChooser/TemplatePathChooser'
import { allFonts } from '@/constants'
import { EventBus, events } from '@/eventbus'
import { createCutOut } from '@/utils/utils'
import TextImageCard from '@/components/TextImageCard'
import { analyticEvents, triggerEvent } from '@/utils/analytics'
import HorizontalCardScroller from '@/components/HorizontalCardScroller'

export default {
    name: 'PrintGenerator',
    components: {
        HorizontalCardScroller,
        TextImageCard,
        TemplatePathChooser,
        OrderResults,
        PrintPreview,
        ImageTextCard,
    },
    data() {
        return {
            colorFor: 'background',
            allFonts: allFonts,
            drawingDimensions: {
                width: 0,
                height: 0,
                bottom: 0,
            },
            productsArray: productsArray,
            template: getTemplate(['print', 'poster', 'vertical', 'a4']),
            sizes: [
                {
                    value: 'a4',
                    label: 'A4',
                },
                {
                    value: 'a3',
                    label: 'A3',
                },
                {
                    value: 'a2',
                    label: 'A2',
                },
            ],
            lastScrollPosition: null,
            showFixedToPreviewButton: false,
            scrollListenerIsActive: false,
        }
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters([
            'customization',
            'resultImage',
            'currentDrawing',
            'key',
        ]),
        maskCards() {
            return this.currentTemplate && this.currentTemplate.masks
                ? this.currentTemplate.masks.map((mask) => {
                      return {
                          id: mask.isDelete ? '' : mask.path,
                          image: require(`@/assets/images/printGenerator/${mask.path}`),
                          onClick: () => {
                              this.maskPath = mask.isDelete ? '' : mask.path
                          },
                      }
                  })
                : []
        },
        backgroundTextureCards() {
            return this.currentTemplate &&
                this.currentTemplate.backgroundTextures
                ? this.currentTemplate.backgroundTextures.map(
                      (backgroundTexture) => {
                          return {
                              id: backgroundTexture.isDelete
                                  ? ''
                                  : backgroundTexture.path,
                              image: require(`@/assets/images/printGenerator/${backgroundTexture.path}`),
                              onClick: () => {
                                  this.backgroundTexturePath =
                                      backgroundTexture.isDelete
                                          ? ''
                                          : backgroundTexture.path
                              },
                          }
                      }
                  )
                : []
        },
        templatePath: {
            get() {
                return this.customization.templatePath
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    templatePath: value,
                })
                triggerEvent(analyticEvents.EDITOR_TEMPLATE, {
                    template: value?.[this.templatePath.length - 1],
                })
            },
        },
        tabIndex() {
            return productsArray.findIndex(
                (product) => product.key === this.customization.templatePath[0]
            )
        },
        expansionPanel: {
            get() {
                return this.customization.expansionPanel
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    expansionPanel: value,
                })
            },
        },
        textSize: {
            get() {
                return this.customization.textSize
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    textSize: value,
                })
                triggerEvent(analyticEvents.EDITOR_TEXT_SIZE, {
                    textSize: value,
                })
            },
        },
        textFont: {
            get() {
                return this.customization.textFont
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    textFont: value,
                })
                triggerEvent(analyticEvents.EDITOR_TEXT_FONT, {
                    textFont: value,
                })
            },
        },
        maskPath: {
            get() {
                return this.customization.maskPath
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    maskPath: value,
                })
                triggerEvent(analyticEvents.EDITOR_MASK, {
                    maskPath: value,
                })
            },
        },
        backgroundTexturePath: {
            get() {
                return this.customization.backgroundTexturePath
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    backgroundTexturePath: value,
                })
                triggerEvent(analyticEvents.EDITOR_IMAGE_BACKGROUND, {
                    backgroundTexture: value,
                })
            },
        },
        backgroundColor: {
            get() {
                return this.customization.backgroundColor
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    backgroundColor: value,
                })
                triggerEvent(analyticEvents.EDITOR_IMAGE_BACKGROUND, {
                    backgroundColor: value,
                })
            },
        },
        text: {
            get() {
                return this.customization.text
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    text: value,
                })
                triggerEvent(analyticEvents.EDITOR_TEXT, {
                    text: value,
                })
            },
        },
        textColor: {
            get() {
                return this.customization.textColor
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    textColor: value,
                })
                triggerEvent(analyticEvents.EDITOR_TEXT_COLOR, {
                    textColor: value,
                })
            },
        },
        useClippedImage: {
            get() {
                return this.customization.useClippedImage
            },
            set(value) {
                this.setCustomization({
                    ...this.customization,
                    useClippedImage: value,
                })
                triggerEvent(analyticEvents.EDITOR_REMOVE_BACKGROUND, {
                    useClippedImage: value,
                })
            },
        },
        useCroppedImage() {
            return this.customization.useCroppedImage
        },
        currentTemplate() {
            return getTemplate(this.customization.templatePath)
        },
    },
    watch: {
        useClippedImage(newVal, oldVal) {
            const defaultBackgroundColor = '#B3E5FCFF'
            this.setCroppedImage(null)
            if (newVal) {
                if (this.hideColorPickerSliders) {
                    this.backgroundColor = defaultBackgroundColor
                }
                this.cutOutBackground()
            } else {
                this.backgroundColor = '#FFFFFFFF'
            }
        },
        templatePath: {
            handler(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.textSize = this.currentTemplate.text.maxSize / 2
                }
            },
        },
        useCroppedImage: {
            handler() {
                triggerEvent(analyticEvents.EDITOR_CROP_IMAGE)
            },
        },
    },
    async created() {
        if (this.id !== this.currentDrawing.uuid) {
            const drawingObject = await this.getDrawing(this.id)
            this.setCurrentDrawing(drawingObject)
        }
        window.addEventListener('scroll', this.onScroll)
    },
    destroyed() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        ...mapMutations([
            'setCustomization',
            'setCroppedImage',
            'setCurrentDrawing',
            'setClippedImage',
        ]),
        ...mapActions(['getDrawing', 'saveClippedImage']),
        handleResize() {
            this.drawingDimensions.width = '86%'
            this.drawingDimensions.height =
                this.$refs?.printPreview?.clientHeight
            this.drawingDimensions.bottom = 0
        },
        changeProduct(productKey) {
            this.templatePath = [
                productKey,
                ...products[productKey].defaultPath,
            ]
        },
        async cutOutBackground() {
            this.setClippedImage(null)
            let maskFileUrl = this.currentDrawing.cutoutMask
            if (!maskFileUrl) {
                const createMaskFile = httpsCallable(
                    functions,
                    'createMaskFile'
                )

                const res = await createMaskFile({
                    result_id: this.id,
                    key: this.key,
                })

                if (res.data.success) {
                    const cutoutMask = res.data?.cutout_mask
                    await this.setCurrentDrawing({
                        ...this.currentDrawing,
                        cutoutMask: cutoutMask,
                    })
                    maskFileUrl = cutoutMask
                } else {
                    EventBus.$emit(events.SNACKBAR, {
                        message:
                            'Leider ist es nicht möglich, das von dir gewählte Bild freizustellen 😔 Bitte versuche es später erneut.',
                        type: 'error',
                    })
                }
            }

            const imageDataUrl = this.currentDrawing.resultImageCompressed
            const cutoutBase64 = await createCutOut(
                imageDataUrl,
                maskFileUrl,
                0.5
            )
            await this.saveClippedImage(cutoutBase64)
        },
        visibilityChanged(isVisible) {
            this.showFixedToPreviewButton = !isVisible
        },
        scrollTo() {
            if (this.lastScrollPosition) {
                this.$nextTick(() => {
                    window.scrollTo({
                        top: this.lastScrollPosition,
                        left: 0,
                        behavior: 'smooth',
                    })
                })
            } else {
                this.lastScrollPosition = window.pageYOffset
                this.$refs.previewContainer.scrollIntoView({
                    behavior: 'smooth',
                })
                setTimeout(() => {
                    this.scrollListenerIsActive = true
                }, 1000)
            }
        },
        onScroll() {
            if (this.scrollListenerIsActive) {
                this.lastScrollPosition = null
                this.scrollListenerIsActive = false
            }
        },
        orderResult() {
            const {
                templatePath,
                backgroundColor,
                text,
                textColor,
                textSize,
                textFont,
                useClippedImage,
                croppedImage,
            } = this.customization
            triggerEvent(analyticEvents.OPEN_ORDER, {
                templatePath,
                backgroundColor,
                text,
                textColor,
                textSize,
                textFont,
                useClippedImage,
                croppedImage: !!croppedImage,
            })
            this.$router.push(`/order/${this.currentDrawing.uuid}`)
        },
        hideColorPickerSliders(color) {
            return (
                color === '#FFFFFFFF' ||
                color === '#000000FF' ||
                color === '#00000000'
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.go-to-preview-btn {
    z-index: 100;
    right: 30px;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;

    &__show {
        opacity: 1;
        visibility: visible;
        transition: all 1s ease;
    }
}

.print-preview-container {
    position: sticky;
    top: 12px;
}

.v-color-picker {
    ::v-deep .v-color-picker__swatches > div {
        min-width: max-content !important;
        justify-content: flex-start;
    }
}

.text-color-picker {
    .v-color-picker {
        ::v-deep .v-color-picker__swatches > div {
            flex-flow: row-reverse;
        }
    }
}
</style>
