<template>
    <div class="products-preview">
        <h1
            class="product-header"
            :class="{
                'product-header--is-selected': isSelected,
            }"
        >
            {{ productType.title }}
        </h1>
        <div v-if="productType.mockups">
            <MockupImageWrapper
                class="mockup-image-wrapper"
                :mockup-img-src-lazy="productType.mockups[0].fileLazy"
                :mockup-img-src="productType.mockups[0].file"
                :product-image-corners="productType.mockups[0].corners"
            >
                <PrintPreview
                    :custom-template="productType.customTemplate"
                    :disabled="true"
                    :use-aspect-ratio="true"
                />
            </MockupImageWrapper>
        </div>
        <PrintPreview
            class="print-preview"
            v-else
            :custom-template="productType.customTemplate"
            :disabled="true"
        />
        <div
            class="product-price-container"
            :class="{
                'product-price-container--is-selected': isSelected,
            }"
        >
            <div class="product-price-container__price">{{ price }}</div>
            <div class="product-price-shipping">{{ shipping }}</div>
        </div>
    </div>
</template>

<script>
import PrintPreview from '@/components/PrintGenerator/components/PrintPreview'
import { mapGetters } from 'vuex'
import MockupImageWrapper from '@/components/OrderResult/MockupImageWrapper'
export default {
    name: 'ProductPreview',
    components: { MockupImageWrapper, PrintPreview },
    data() {
        return {}
    },
    props: {
        productType: {
            required: true,
        },
    },
    computed: {
        ...mapGetters(['selectedProductType', 'totalPrice']),
        shipping() {
            return this.productType.isDownload
                ? 'kein Versand'
                : this.productType.shipping
                ? `zzgl. ${this.productType.shipping}€ Versand`
                : 'inkl.Versand'
        },
        startingPrice() {
            return this.productType.variants[0].price
                ? `ab ${this.productType.variants[0].price}€`
                : 'Kostenlos'
        },
        price() {
            if (this.isSelected) {
                return this.totalPrice ? `${this.totalPrice}€` : 'Kostenlos'
            } else {
                return this.startingPrice
            }
        },
        isSelected() {
            return this.selectedProductType.id === this.productType.id
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.products-preview {
    user-select: none;

    &__container {
    }
}

.mockup-image-wrapper {
    box-shadow: rgba(50, 50, 93, 0.25) 0 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -20px;
}

.print-preview {
    border: black solid 1px;
}

.product-header {
    text-align: center;
    width: 100%;
    margin-bottom: 6px;
    font-weight: 400;
    transition: all ease-out 0.7s;
    min-height: 48px;

    &--is-selected {
        margin-bottom: 16px;
    }
}

.product-price-container {
    margin-top: 12px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    left: 0;
    min-height: 100px;

    &__price {
        font-weight: bold;
    }

    &--is-selected {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        transition: all ease-out 0.5s;
        text-align: center;
        min-height: 100px;

        .product-price-container__price {
            font-size: 33px;
        }
    }
}
</style>
