var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.handleResize),expression:"handleResize"}],ref:"printGeneratorContainer",staticClass:"mt-12"},[_c('v-row',{staticStyle:{"min-height":"500px"}},[(_vm.productsArray.length > 1)?_c('v-tabs',{attrs:{"value":_vm.tabIndex}},_vm._l((_vm.productsArray),function(product){return _c('v-tab',{key:product.key,on:{"click":() => _vm.changeProduct(product.key)}},[(product.badge)?_c('v-badge',{attrs:{"offset-x":20,"offset-y":0,"content":product.badge,"color":"success"}},[_vm._v(" "+_vm._s(product.label)+" ")]):_c('div',[_vm._v(" "+_vm._s(product.label)+" ")])],1)}),1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
                    callback: _vm.visibilityChanged,
                    intersection: {
                        threshold: 0.5,
                    },
                }),expression:"{\n                    callback: visibilityChanged,\n                    intersection: {\n                        threshold: 0.5,\n                    },\n                }"}],ref:"previewContainer",staticClass:"print-preview-container"},[_c('PrintPreview')],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-expansion-panels',{attrs:{"accordion":""},model:{value:(_vm.expansionPanel),callback:function ($$v) {_vm.expansionPanel=$$v},expression:"expansionPanel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Vorlage Wählen")]),_c('v-expansion-panel-content',[_c('TemplatePathChooser')],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Maske Wählen")]),_c('v-expansion-panel-content',[_c('div',{key:_vm.templatePath.toString()},[_c('HorizontalCardScroller',{attrs:{"selected-card":_vm.customization.maskPath,"cards":_vm.maskCards}})],1)])],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Bild Freistellen")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6","lg":"4"}},[_c('TextImageCard',{attrs:{"description":"Original Hintergrund","selected":!_vm.useClippedImage,"image":require(`@/assets/images/printGenerator/horizontal_inset.jpg`),"lazy":require(`@/assets/images/printGenerator/horizontal_inset_lazy.jpg`),"on-select":() => {
                                            _vm.useClippedImage = false
                                        }}})],1),_c('v-col',{attrs:{"cols":"6","md":"6","lg":"4"}},[_c('TextImageCard',{attrs:{"description":"Eigener Hintergrund","image":require(`@/assets/images/printGenerator/horizontal_cutout.jpg`),"lazy":require(`@/assets/images/printGenerator/horizontal_cutout_lazy.jpg`),"selected":_vm.useClippedImage,"on-select":() => {
                                            _vm.useClippedImage = true
                                        }}})],1)],1),(_vm.useClippedImage)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Hintergrund wählen")]),_c('v-divider'),_c('HorizontalCardScroller',{staticClass:"mt-6",attrs:{"selected-card":_vm.customization.backgroundTexturePath,"cards":_vm.backgroundTextureCards}})],1)],1):_vm._e(),(
                                _vm.useClippedImage &&
                                !_vm.customization.backgroundTexturePath
                            )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',[_vm._v("Farbe wählen")]),_c('v-divider'),_c('v-color-picker',{staticClass:"mt-6",attrs:{"dot-size":"40","hide-sliders":_vm.hideColorPickerSliders(
                                            _vm.backgroundColor
                                        ),"hide-canvas":"","canvas-height":"50px","hide-inputs":"","hide-mode-switch":"","show-swatches":"","swatches-max-height":"400","width":"100%"},model:{value:(_vm.backgroundColor),callback:function ($$v) {_vm.backgroundColor=$$v},expression:"backgroundColor"}})],1)],1):_vm._e()],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Personalisierungen")]),_c('v-expansion-panel-content',[_c('v-text-field',{attrs:{"label":"Name Deines Lieblings","outlined":""},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}),_c('v-select',{attrs:{"outlined":"","items":_vm.allFonts,"item-value":"value","label":"Schriftart"},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{style:({ fontFamily: item.value })},[_vm._v(" "+_vm._s(_vm.text || item.label)+" ")])]}},{key:"selection",fn:function({ item }){return [_c('div',{style:({ fontFamily: item.value })},[_vm._v(" "+_vm._s(item.label)+" ")])]}}]),model:{value:(_vm.textFont),callback:function ($$v) {_vm.textFont=$$v},expression:"textFont"}}),_c('v-slider',{attrs:{"label":"Schriftgröße","hint":"Im a hint","max":_vm.currentTemplate?.text?.maxSize,"min":_vm.currentTemplate?.text?.minSize,"step":"0.1"},model:{value:(_vm.textSize),callback:function ($$v) {_vm.textSize=$$v},expression:"textSize"}}),_c('div',{staticClass:"text-color-picker"},[_c('v-color-picker',{attrs:{"dot-size":"40","hide-sliders":_vm.hideColorPickerSliders(_vm.textColor),"hide-canvas":"","canvas-height":"50px","hide-inputs":"","hide-mode-switch":"","show-swatches":"","swatches-max-height":"400","width":"100%"},model:{value:(_vm.textColor),callback:function ($$v) {_vm.textColor=$$v},expression:"textColor"}})],1)],1)],1)],1),_c('v-btn',{staticClass:"mt-3",attrs:{"x-large":"","color":"success","width":"100%"},on:{"click":_vm.orderResult}},[_vm._v("Weiter")])],1)],1),_c('v-btn',{staticClass:"go-to-preview-btn",class:{
            'go-to-preview-btn__show':
                _vm.showFixedToPreviewButton || _vm.lastScrollPosition,
        },attrs:{"rounded":"","fixed":"","bottom":"","dark":"","right":""},on:{"click":_vm.scrollTo}},[_vm._v(" "+_vm._s(_vm.lastScrollPosition ? 'Zurück' : 'Zur Vorschau')+" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.lastScrollPosition ? 'mdi-arrow-down-thick' : 'mdi-arrow-up-thick'))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }