<template>
    <v-container class="main-view-container">
        <MainHeader class="main-header" />
        <v-row justify="center">
            <v-col
                v-if="!myDrawings.length"
                cols="12"
                class="first-drawing-note"
            >
                <v-img width="500px" max-width="80vw" :src="firstDrawingNote" />
            </v-col>
            <v-col
                v-for="(drawing, index) in myDrawings"
                :key="index"
                cols="12"
                sm="6"
                lg="4"
            >
                <DrawingCard :drawing="drawing" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <GeneratorCard />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import GeneratorCard from '@/components/GeneratorCard'
import DrawingCard from '@/components/DrawingCard'
import { mapGetters } from 'vuex'

export default {
    name: 'MainView',
    components: {
        DrawingCard,
        GeneratorCard,
        MainHeader,
    },
    computed: {
        ...mapGetters(['myDrawings']),
        firstDrawingNote() {
            if (window.screen.availWidth < 800) {
                return require('@/assets/images/firstDrawing_mobile.png')
            } else {
                return require('@/assets/images/firstDrawing_desktop.png')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.main-view-container {
    padding-top: 0 !important;
    @media only screen and (max-width: 600px) {
        max-width: 93vw;
    }
}

.first-drawing-note {
    display: inline-block;
    position: relative;

    @media only screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
    }
}
</style>
