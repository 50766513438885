const productInformationCanvas = `<ul><li>Leinwand per Hand auf 2cm Keilrahmen gespannt</li>
<li>Gestochen scharfer Druck & kräftige, strahlende Farben</li>
<li>In Deutschland unter Einsatz modernster Druckverfahren bedruckt</li>
<li>Fichtenholz Keilrahmen aus nachhaltiger Forstwirtschaft</li>
<li>Leichte Farbabweichungen beim Druck möglich</li></ul>`

const productInformationPoster = `<ul><li>Hochwertiges Fotopapier mit mattem Finish in Galeriequalität</li>
<li>In Deutschland unter Einsatz modernster Druckverfahren bedruckt</li>
<li>Kräftige, strahlende Farben und ein gestochen scharfer Druck</li>
<li>Leichte Farbabweichungen beim Druck möglich</li>
<li>Druck wird gerollt versendet, es empfiehlt sich einen Rahmen zu verwenden.</li></ul>`

const shippingInformation = `<p>Nach Abschluss des Bestellvorgangs wird das Produkt innerhalb von 5 Werktagen verschickt. Der Versand erfolgt als getracktes Paket, die Lieferzeit kann je nach Zielort variieren. Sobald das Produkt verschickt wurde, erhältst du eine Versandbestätigung mit einer Tracking-Nummer, mit der du den Status deiner Lieferung verfolgen kannst.</p><p>Bitte stelle sicher, dass du während des Bestellvorgangs die korrekte Lieferadresse angegeben hast, um sicherzustellen, dass das Produkt an den richtigen Ort geliefert wird. Wenn du irgendwelche Fragen oder Probleme bezüglich des Versands hast, kannst du uns jederzeit kontaktieren. Wir sind bemüht, dir den bestmöglichen Kundenservice zu bieten.</p>`

const verticalMockups = [
    {
        file: 'url/to/image.png',
        position: {
            lt: 0.12,
            lb: 0.2,
            rt: 0.12,
            rb: 0.2,
        },
    },
]

const printProductTypes = {
    file: {
        id: 'file',
        title: 'Download',
        shipping: 0.0,
        mockupFile: '',
        isDownload: true,
        informationBlocks: [
            {
                title: 'Produktinformationen',
                content: `<p>Unser <b>kostenloser Download</b> enthält ein hochwertiges Bild im PNG-Format, das du jederzeit ausdrucken kannst. Die Grafik hat das <b>DIN-Format</b> welches sich perfekt für die meisten Druckerformate eignet. Nachdem du den Download abgeschlossen hast, kannst du das Bild einfach auf deinem Computer speichern und es dann auf dein bevorzugtes Druckmedium ausdrucken. Wir empfehlen dir, das Bild auf hochwertigem Papier oder Leinwand auszudrucken, um sicherzustellen, dass es in seiner besten Qualität dargestellt wird.</p>
<p>Wir hoffen, dass dir dieses kostenlose Bild gefällt und freuen uns darauf, weitere hochwertige Downloads mit dir zu teilen. Vielen Dank, dass du dich für unser Produkt entschieden hast!</p><p><b>Bitte beachten Sie, dass das Bild für den persönlichen Gebrauch bestimmt ist und nicht für den Verkauf oder die kommerzielle Nutzung lizenziert ist.</b></p>`,
            },
        ],
        variants: [
            {
                label: '',
                price: 0.0,
                sku: 'download_01',
            },
        ],
    },
    print: {
        id: 'print',
        title: 'Druck',
        shipping: 0.0,
        informationBlocks: [
            {
                title: 'Produktinformationen',
                content: productInformationPoster,
            },
            {
                title: 'Versand',
                content: shippingInformation,
            },
        ],
        variants: [
            {
                label: '30x20cm',
                price: 14.95,
                mockups: verticalMockups,
                sku: 'print_30x20',
            },
            {
                label: '60x40cm',
                price: 24.95,
                sku: 'print_60x40',
            },
            {
                label: '75x50cm',
                price: 29.95,
                sku: 'print_75x50',
            },
        ],
    },
    canvas: {
        id: 'canvas',
        title: 'Leinwand',
        shipping: 0.0,
        informationBlocks: [
            {
                title: 'Produktinformationen',
                content: productInformationCanvas,
            },
            {
                title: 'Versand',
                content: shippingInformation,
            },
        ],
        variants: [
            {
                label: '30x20cm',
                price: 24.95,
                sku: 'canvas_30x20',
            },
            {
                label: '60x40cm',
                price: 34.95,
                sku: 'canvas_60x40',
            },
            {
                label: '75x50cm',
                price: 44.95,
                sku: 'canvas_75x50',
            },
            {
                label: '90x60cm',
                price: 54.95,
                sku: 'canvas_90x60',
            },
        ],
    },
}

const printProductTypesVertical = [
    {
        ...printProductTypes.file,
    },
    {
        ...printProductTypes.print,
        mockups: [
            {
                file: require('@/assets/images/mockups/print/print_mockup.png'),
                fileLazy: require('@/assets/images/mockups/print/print_mockup_lazy.png'),
                corners: [
                    [0.3262, 0.1745],
                    [0.873, 0.1752],
                    [0.8446, 0.7071],
                    [0.2971, 0.6941],
                ],
            },
        ],
        customTemplate: {
            templateFile: require('@/assets/images/printGenerator/templates/print/default_vertical_no_watermark.png'),
            templateFileLazy: require('@/assets/images/printGenerator/templates/print/default_vertical_no_watermark.png'),
        },
    },
    {
        ...printProductTypes.canvas,
        mockups: [
            {
                file: require('@/assets/images/mockups/canvas/canvas_mockup.jpg'),
                fileLazy: require('@/assets/images/mockups/canvas/canvas_mockup_lazy.jpg'),
                corners: [
                    [0.1848, 0.1955],
                    [0.7382, 0.1895],
                    [0.8607, 0.7918],
                    [0.3112, 0.8629],
                ],
            },
        ],
        customTemplate: {
            templateFile: require('@/assets/images/printGenerator/templates/print/default_vertical_no_watermark.png'),
            templateFileLazy: require('@/assets/images/printGenerator/templates/print/default_vertical_no_watermark.png'),
        },
    },
]

const printProductTypesHorizontal = [
    {
        ...printProductTypes.file,
    },
    {
        ...printProductTypes.print,
    },
    {
        ...printProductTypes.canvas,
    },
]

const horizontalMasks = [
    {
        isDelete: true,
        path: 'masks/horizontal/no-mask.png',
    },
    {
        path: 'masks/horizontal/mask1.png',
    },
    {
        path: 'masks/horizontal/mask2.png',
    },
    {
        path: 'masks/horizontal/mask3.png',
    },
    {
        path: 'masks/horizontal/mask4.png',
    },
    {
        path: 'masks/horizontal/mask5.png',
    },
    {
        path: 'masks/horizontal/mask6.png',
    },
    {
        path: 'masks/horizontal/mask7.png',
    },
    {
        path: 'masks/horizontal/mask8.png',
    },
    {
        path: 'masks/horizontal/mask9.png',
    },
    {
        path: 'masks/horizontal/mask10.png',
    },
    {
        path: 'masks/horizontal/mask11.png',
    },
    {
        path: 'masks/horizontal/mask12.png',
    },
    {
        path: 'masks/horizontal/mask13.png',
    },
    {
        path: 'masks/horizontal/mask14.png',
    },
    {
        path: 'masks/horizontal/mask15.png',
    },
    {
        path: 'masks/horizontal/mask16.png',
    },
    {
        path: 'masks/horizontal/mask17.png',
    },
    {
        path: 'masks/horizontal/mask18.png',
    },
    {
        path: 'masks/horizontal/mask19.png',
    },
    {
        path: 'masks/horizontal/mask20.png',
    },
    {
        path: 'masks/horizontal/mask21.png',
    },
]
const verticalMasks = [
    {
        isDelete: true,
        path: 'masks/vertical/no-mask.png',
    },
    {
        path: 'masks/vertical/mask1.png',
    },
    {
        path: 'masks/vertical/mask2.png',
    },
    {
        path: 'masks/vertical/mask3.png',
    },
    {
        path: 'masks/vertical/mask4.png',
    },
    {
        path: 'masks/vertical/mask5.png',
    },
    {
        path: 'masks/vertical/mask6.png',
    },
    {
        path: 'masks/vertical/mask7.png',
    },
    {
        path: 'masks/vertical/mask8.png',
    },
    {
        path: 'masks/vertical/mask9.png',
    },
    {
        path: 'masks/vertical/mask10.png',
    },
    {
        path: 'masks/vertical/mask11.png',
    },
    {
        path: 'masks/vertical/mask12.png',
    },
    {
        path: 'masks/vertical/mask13.png',
    },
    {
        path: 'masks/vertical/mask14.png',
    },
    {
        path: 'masks/vertical/mask15.png',
    },
    {
        path: 'masks/vertical/mask16.png',
    },
    {
        path: 'masks/vertical/mask17.png',
    },
    {
        path: 'masks/vertical/mask18.png',
    },
    {
        path: 'masks/vertical/mask19.png',
    },
    {
        path: 'masks/vertical/mask20.png',
    },
    {
        path: 'masks/vertical/mask21.png',
    },
]

const horizontalBackgroundTextures = [
    {
        isDelete: true,
        path: 'masks/horizontal/no-mask.png',
    },
    {
        path: 'backgroundTextures/horizontal/texture1.jpg',
    },
    {
        path: 'backgroundTextures/horizontal/texture2.jpg',
    },
    {
        path: 'backgroundTextures/horizontal/texture3.jpg',
    },
]

const verticalBackgroundTextures = [
    {
        isDelete: true,
        path: 'masks/vertical/no-mask.png',
    },
    {
        path: 'backgroundTextures/vertical/texture1.jpg',
    },
    {
        path: 'backgroundTextures/vertical/texture2.jpg',
    },
    {
        path: 'backgroundTextures/vertical/texture3.jpg',
    },
]

const defaultVerticalDrawingContainer = {
    aspectRatio: 150,
    drawingContainer: {
        bottom: 16.9324,
        width: 87.3016,
        height: 78.8345,
    },
    text: {
        bottom: 9,
        minSize: 2.0,
        maxSize: 20.0,
    },
    masks: verticalMasks,
    backgroundTextures: verticalBackgroundTextures,
    // Needed for creating the final template on server side
    templateFileUrl:
        'https://firebasestorage.googleapis.com/v0/b/petdrawio.appspot.com/o/app_data%2Fdefault_vertical_no_watermark.png?alt=media&token=655bdb73-5f82-49ce-bf02-e79a8def0c87',
    templateFile: require('@/assets/images/printGenerator/templates/print/default_vertical.png'),
    templateFileLazy: require('@/assets/images/printGenerator/templates/print/lazysrcs/default_vertical_lazy.png'),
}

const defaultHorizontalDrawingContainer = {
    aspectRatio: 66.6666667,
    drawingContainer: {
        bottom: 7.272728,
        width: 91.5338,
        height: 87.3016,
    },
    text: {
        bottom: 8,
        minSize: 4.5,
        maxSize: 19.0,
    },
    masks: horizontalMasks,
    backgroundTextures: horizontalBackgroundTextures,
    // Needed for creating the final template on server side
    templateFileUrl:
        'https://firebasestorage.googleapis.com/v0/b/petdrawio.appspot.com/o/app_data%2Fdefault_horizontal_no_watermark.png?alt=media&token=32716720-d2d1-4d06-98f0-e26dad61f756',
    templateFile: require('@/assets/images/printGenerator/templates/print/default_horizontal.png'),
    templateFileLazy: require('@/assets/images/printGenerator/templates/print/lazysrcs/default_horizontal_lazy.png'),
}

export const products = {
    print: {
        label: 'Druck',
        defaultPath: ['vertical'],
        children: {
            vertical: {
                label: 'Hochformat',
                image: require('@/assets/images/printGenerator/templates/print/tumbnails/vertical_thumb.jpg'),
                lazy: require('@/assets/images/printGenerator/templates/print/tumbnails/vertical_thumb_lazy.jpg'),
                childrenLabel: 'Größe',
                template: {
                    ...defaultVerticalDrawingContainer,
                },
                productTypes: printProductTypesVertical,
            },
            horizontal: {
                label: 'Querformat',
                image: require('@/assets/images/printGenerator/templates/print/tumbnails/horizontal_thumb.jpg'),
                lazy: require('@/assets/images/printGenerator/templates/print/tumbnails/horizontal_thumb_lazy.jpg'),
                childrenLabel: 'Größe',
                template: {
                    ...defaultHorizontalDrawingContainer,
                },
                productTypes: printProductTypesHorizontal,
            },
        },
    },
}

export const getTemplate = (pathArray) => {
    let currentObject = products[pathArray[0]] // Get Product always first in path e.g. ['print','poster','vertical','a4']

    // Resolve Path
    for (let i = 1; i < pathArray.length; i++) {
        currentObject = { ...currentObject?.children?.[pathArray[i]] }
    }
    return currentObject?.template || null
}

export const getLevel = (pathArray) => {
    let currentObject = products[pathArray[0]] // Get Product always first in path e.g. ['print','poster','vertical','a4']

    // Resolve Path
    for (let i = 1; i < pathArray.length; i++) {
        currentObject = {
            ...currentObject?.children?.[pathArray[i]],
        }
    }

    if (currentObject.children) {
        Object.keys(currentObject.children).forEach(function (key, index) {
            currentObject.children[key] = {
                key,
                ...currentObject.children[key],
            }
        })
    }

    return currentObject
}

const createArrayStructure = (object) => {
    return Object.keys(object).map((key) => ({
        key: key,
        ...object?.[key],
        children: object?.[key].children
            ? createArrayStructure(object?.[key].children)
            : undefined,
    }))
}

export const productsArray = createArrayStructure(products)
