import imageCompression from 'browser-image-compression'

export const delayMs = (ms) => new Promise((res) => setTimeout(res, ms))

export function getDataUrl(file, fileType = 'image/png') {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            const dataUrl = reader.result
            const base64Content = dataUrl.split(',')[1]
            const finalDataUrl = `data:${fileType};base64,${base64Content}`
            resolve(finalDataUrl)
        }
        reader.onerror = (error) => reject(error)
    })
}

export function encodeBase64(base64String) {
    let encoded = base64String.replace(/^data:(.*,)?/, '')
    if (encoded.length % 4 > 0) {
        encoded += '='.repeat(4 - (encoded.length % 4))
    }
    return encoded
}

export function loadImage(url) {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.addEventListener('load', () => resolve(img))
        img.addEventListener('error', reject)
        img.crossOrigin = 'anonymous'
        img.src = url
    })
}

export const downloadFileFromUrl = (url, filename, setProgress) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = function () {
        const a = document.createElement('a')
        a.href = window.URL.createObjectURL(xhr.response)
        a.download = filename
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
    }
    xhr.onprogress = (event) => {
        if (setProgress) {
            setProgress(Math.round((event.loaded / event.total) * 100))
        }
    }
    xhr.open('GET', url)
    xhr.send()
}

export const downloadImageFromUrl = (url, filename, setProgress) => {
    const name = filename + '.png'
    downloadFileFromUrl(url, name, setProgress)
}

export const mergeImagesWithOpacity = async (
    effectImage,
    originalImage,
    effectStrength,
    maxSizeMB
) => {
    // Load the two images from URLs
    const effectImg = new Image()
    effectImg.crossOrigin = 'anonymous'
    effectImg.src = effectImage
    const originalImg = new Image()
    originalImg.crossOrigin = 'anonymous'
    originalImg.src = originalImage

    // Wait for both images to load before merging
    await Promise.all(
        [effectImg, originalImg].map(
            (img) => new Promise((resolve) => (img.onload = resolve))
        )
    )

    // Create a canvas element and set its size to the image dimensions
    const canvas = document.createElement('canvas')
    canvas.width = originalImg.width
    canvas.height = originalImg.height

    // Get the canvas context and draw the second image
    const ctx = canvas.getContext('2d')
    ctx.drawImage(originalImg, 0, 0)

    // Set the opacity for the first image
    ctx.globalAlpha = effectStrength

    // Draw the first image on top of the second
    ctx.drawImage(effectImg, 0, 0, originalImg.width, originalImg.height)

    const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/png', 1)
    })

    const compressedImage = await compressBlob(blob, maxSizeMB)
    return maxSizeMB ? compressedImage : getDataUrl(blob)
}

export async function createCutOut(imageDataUrl, maskUrl, maxSizeMB) {
    const mask = new Image()
    const image = new Image()
    mask.crossOrigin = 'anonymous'
    mask.src = maskUrl
    image.crossOrigin = 'anonymous'
    image.src = imageDataUrl
    await Promise.all([
        new Promise((resolve) => (mask.onload = resolve)),
        new Promise((resolve) => (image.onload = resolve)),
    ])

    // Create a canvas with the same dimensions as the images
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height

    // Draw the first image onto the canvas
    const ctx = canvas.getContext('2d')
    ctx.drawImage(mask, 0, 0, image.width, image.height)
    // Use the first image as a mask for the second image
    ctx.globalCompositeOperation = 'source-in'
    ctx.drawImage(image, 0, 0)

    // Export the result as a new image or use the canvas directly
    const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/png', 1)
    })

    const compressedImage = await compressBlob(blob, maxSizeMB)
    return maxSizeMB ? compressedImage : getDataUrl(blob)
}

async function compressBlob(blob, maxSizeMB, maxWidthOrHeight = 1200) {
    const options = {
        maxSizeMB,
        maxWidthOrHeight: maxWidthOrHeight,
        useWebWorker: true,
    }
    const compressedBlob = await imageCompression(blob, options)
    const compressedDataUrl = await getDataUrl(compressedBlob)

    return compressedDataUrl
}

export async function getCroppedImage(imageToCrop, cropperValues) {
    const image = await loadImage(imageToCrop)

    // Define the region to cut out
    const x = image.width * cropperValues.relative_x
    const y = image.height * cropperValues.relative_y
    const width = image.width * cropperValues.relative_width
    const height = image.height * cropperValues.relative_height

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, x, y, width, height, 0, 0, width, height)
    const croppedImageBlob = await new Promise((resolve) =>
        canvas.toBlob(resolve, 'image/png')
    )
    return getDataUrl(croppedImageBlob)
}
