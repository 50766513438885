<template>
    <div class="resultsContainer">
        <div class="resultsContainer__content">
            <div v-if="carouselHeight < 200" class="loading-carousel">
                <v-progress-circular indeterminate size="60" />
            </div>
            <carousel-3d
                :minSwipeDistance="40"
                ref="carousel3d"
                :inverse-scaling="600"
                :perspective="40"
                @before-slide-change="onAfterSlideChange"
                :space="sliderSpacing"
                class="products-carousel"
                :height="carouselHeight"
                :controls-visible="true"
                :clickable="false"
                :style="`opacity: ${
                    carouselHeight > 200 ? 1 : 0
                }; height: ${carouselHeight}px !important`"
            >
                <slide v-for="(productType, i) in productTypes" :index="i">
                    <div class="products-carousel__slide-content">
                        <ProductPreview
                            :productType="productType"
                            ref="productView"
                        />
                    </div>
                </slide>
            </carousel-3d>
            <div class="d-flex flex-column align-center">
                <div class="order-form">
                    <v-select
                        v-if="selectedProductType.variants.length > 1"
                        :items="selectedProductType.variants"
                        v-model="selectedVariant"
                        item-text="label"
                        :item-value="(item, index) => item"
                        outlined
                        label="Größe"
                        width="100%"
                    ></v-select>
                    <div v-if="selectedProductType.isDownload">
                        <ProgressButton
                            :disabled="!!isDownloading"
                            :loading="isDownloading === 'download'"
                            loading-text="Kunstwerk wird erstellt"
                            label="Herunterladen"
                            :on-click-handler="downloadResult"
                        />
                        <div class="sponsor-info">Gesponsert von Fressnapf</div>
                    </div>
                    <div v-else>
                        <ProgressButton
                            :disabled="!!isDownloading"
                            :loading="isDownloading === 'stripe'"
                            :loading-text="orderProcessingText"
                            :progress="orderProcessingProgress"
                            label="Zum Checkout"
                            :on-click-handler="goToStripeCheckout"
                        />
                        <div class="payment-methods">
                            <div class="payment-methods__description">
                                Sicher zahlen mit:
                            </div>
                            <v-img
                                max-width="39px"
                                v-for="(method, index) in paymentMethods"
                                :key="index"
                                :src="method.img"
                                :alt="method.alt"
                            />
                        </div>
                        <v-divider class="mt-6" />
                        <div class="text-center mt-3">
                            Oder Express-Checkout mit:
                        </div>
                        <ProgressButton
                            class="mt-3"
                            color="#FFCC00"
                            :disabled="!!isDownloading"
                            :loading="isDownloading === 'paypal'"
                            :loading-text="orderProcessingText"
                            :progress="orderProcessingProgress"
                            :label-image="
                                require('@/assets/images/paypal_btn.png')
                            "
                            :on-click-handler="goToPaypalCheckout"
                        />
                    </div>
                    <ProductInformationBlocks
                        class="mt-12"
                        v-if="selectedProductType.informationBlocks"
                        :information-blocks="
                            selectedProductType.informationBlocks
                        "
                    />
                    <div
                        v-if="!selectedProductType.isDownload"
                        class="price-info"
                    >
                        Lieferung ohne Rahmen | Preis inkl. MwSt.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PrintPreview from '@/components/PrintGenerator/components/PrintPreview'
import { mapGetters, mapMutations } from 'vuex'
import { downloadFileFromUrl } from '@/utils/utils'
import { createFinalTemplate } from '@/utils/createFinalTemplate'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import ProductPreview from '@/components/OrderResult/ProductPreview'
import ImageMockup from '@/components/OrderResult/MockupImageWrapper'
import { getLevel, getTemplate } from '@/content/products'
import ProductInformationBlocks from '@/components/OrderResult/ProductInformationBlocks'
import { httpsCallable } from 'firebase/functions'
import { functions } from '../../../firebase'
import QuantitySelector from '@/components/QuantitySelector'
import { allPaymentMethods } from '@/constants'

import ProgressButton from '@/components/ProgressButton'
import { analyticEvents, triggerEvent } from '@/utils/analytics'

export default {
    name: 'OrderResults',
    components: {
        ProgressButton,
        QuantitySelector,
        ProductInformationBlocks,
        ImageMockup,
        ProductPreview,
        PrintPreview,
        Carousel3d,
        Slide,
    },
    data() {
        return {
            filename: '',
            filetype: 'png',
            drawing: null,
            drawingCutout: null,
            emailRules: [],
            termsRules: [],
            isAcceptingTerms: false,
            isDownloading: false,
            selectedVariant: null,
            resultTemplate: null,
            resultTemplateBlob: null,
            orderProcessingText: '',
            orderProcessingProgress: 0,
            carouselHeight: 0,
        }
    },
    props: {},
    computed: {
        ...mapGetters([
            'customization',
            'currentDrawing',
            'templatePath',
            'selectedProductType',
            'selectedSku',
            'quantity',
            'key',
        ]),
        productTypes() {
            return getLevel(this.templatePath).productTypes
        },
        sliderSpacing() {
            return window.innerWidth / 2
        },
        paymentMethods() {
            return allPaymentMethods
        },
    },
    watch: {
        productTypes: {
            handler() {
                this.$nextTick(() => {
                    this.calculateCarouselHeight()
                })
            },
            deep: true,
        },
        selectedVariant: {
            handler() {
                this.setTotalPrice(this.selectedVariant.price)
                this.setSelectedSku(this.selectedVariant.sku)

                triggerEvent(analyticEvents.ORDER_SETTING_CHANGE, {
                    sku: this.selectedSku,
                })
            },
            deep: true,
        },
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.calculateCarouselHeight()
            }, 1000)
        })
    },
    created() {
        this.setSelectedProductType(this.productTypes[0])
        this.filename =
            this.customization?.text
                ?.toLowerCase()
                ?.replace(/ /g, '_')
                .substring(0, 10) || 'MeinPortrait'
    },
    methods: {
        ...mapMutations([
            'setSelectedProductType',
            'setTotalPrice',
            'setSelectedSku',
            'setQuantity',
        ]),
        onAfterSlideChange(index) {
            this.setSelectedProductType(this.productTypes[index])
            this.selectedVariant = this.productTypes[index].variants[0]
        },
        async createFinalTemplate() {
            return await createFinalTemplate({
                currentDrawing: this.currentDrawing,
                effectStrength: this.customization.effectStrength,
                useClippedImage: this.customization.useClippedImage,
                cropperValues: this.customization.cropperValues,
                templatePath: this.customization.templatePath,
                backgroundColor: this.customization.backgroundColor,
                text: this.customization.text,
                textFont: this.customization.textFont,
                textColor: this.customization.textColor,
                textSize: this.customization.textSize,
                customTemplate:
                    this.selectedProductType.customTemplate?.templateFile,
            })
        },
        async downloadResult() {
            this.isDownloading = 'download'
            triggerEvent(analyticEvents.START_DOWNLOAD)
            const finalTemplate = await this.createFinalTemplate()
            downloadFileFromUrl(
                finalTemplate.dataUrl,
                `${this.filename}.${this.filetype}`
            )
            triggerEvent(analyticEvents.END_DOWNLOAD)
            this.isDownloading = null
        },
        async goToPaypalCheckout() {
            this.isDownloading = 'paypal'
            triggerEvent(analyticEvents.START_CHECKOUT_PAYPAL, {
                selectedProductType: this.selectedVariant,
                selectedSku: this.selectedSku,
            })
            this.orderProcessingText = 'Weiterleitung PayPal'

            const createPaypalCheckout = httpsCallable(
                functions,
                'createPayPalCheckout'
            )

            const temp = getTemplate(this.customization.templatePath)
            const templateData = {
                currentDrawing: {
                    ...this.currentDrawing,
                    resultImageCompressed: null,
                },
                effectStrength: this.customization.effectStrength,
                useClippedImage: this.customization.useClippedImage,
                cropperValues: this.customization.cropperValues,
                backgroundColor: this.customization.backgroundColor,
                text: this.customization.text,
                textFont: this.customization.textFont,
                textColor: this.customization.textColor,
                textSize: this.customization.textSize,
                template: temp,
            }

            const lineItems = [
                {
                    quantity: 1,
                    sku: this.selectedSku,
                    templateData,
                },
            ]

            const res = await createPaypalCheckout({
                lineItems,
                cancelUrl: `${process.env.VUE_APP_URL}${this.$router.currentRoute.path}`,
                successUrl: `${process.env.VUE_APP_URL}${this.$router.currentRoute.path}/success`,
                templateData,
            })
            triggerEvent(analyticEvents.END_CHECKOUT_PAYPAL, {
                selectedProductType: this.selectedVariant,
                selectedSku: this.selectedSku,
            })
            this.isDownloading = null
            if (res.data.success) {
                window.location.href = res.data.redirectUrl
            } else {
                console.log(res)
            }
        },
        async goToStripeCheckout() {
            this.isDownloading = 'stripe'
            triggerEvent(analyticEvents.START_CHECKOUT_STRIPE, {
                selectedProductType: this.selectedVariant,
                selectedSku: this.selectedSku,
            })
            this.orderProcessingText = 'Weiterleitung Stripe'

            const createStripeCheckout = httpsCallable(
                functions,
                'createStripeCheckout'
            )
            const temp = getTemplate(this.customization.templatePath)
            const templateData = {
                currentDrawing: {
                    ...this.currentDrawing,
                    resultImageCompressed: null,
                },
                effectStrength: this.customization.effectStrength,
                useClippedImage: this.customization.useClippedImage,
                cropperValues: this.customization.cropperValues,
                backgroundColor: this.customization.backgroundColor,
                text: this.customization.text,
                textFont: this.customization.textFont,
                textColor: this.customization.textColor,
                textSize: this.customization.textSize,
                template: temp,
            }

            const lineItems = [
                {
                    quantity: 1,
                    sku: this.selectedSku,
                    templateData: templateData,
                },
            ]

            const res = await createStripeCheckout({
                lineItems,
                cancelUrl: `${process.env.VUE_APP_URL}${this.$router.currentRoute.path}`,
                successUrl: `${process.env.VUE_APP_URL}${this.$router.currentRoute.path}/success`,
                templateData,
            })
            triggerEvent(analyticEvents.END_CHECKOUT_STRIPE, {
                selectedProductType: this.selectedVariant,
                selectedSku: this.selectedSku,
            })
            this.isDownloading = null
            window.location.href = res.data.redirectUrl
        },
        calculateCarouselHeight() {
            let maxHeight = 0
            this.$refs.productView.forEach((productView) => {
                let slideHeight = productView.$el.offsetHeight
                if (slideHeight > maxHeight) {
                    maxHeight = slideHeight
                }
            })
            this.carouselHeight = maxHeight
        },
        onResize() {
            if (this.carouselHeight > 0) {
                // this.calculateCarouselHeight()
                this.carouselHeight += 1 // Height need to be updated in order to trigger update
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.resultsContainer {
    display: flex;
    justify-content: center;
    background-color: transparent;
    overflow: visible;

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 90%;
    }
}

.loading-carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
}

.products-carousel {
    background-color: unset !important;
    border: unset !important;
    overflow: visible !important;
    max-width: 70vw;

    &__slide-content {
        width: 100%;
        height: auto;
    }
}

.carousel-3d-slide {
    background-color: transparent !important;
    border: unset !important;
    overflow: visible !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

::v-deep .carousel-3d-container .carousel-3d-controls {
    width: 460px;
    left: 0;
    right: 0;
    margin: auto;

    @media only screen and (max-width: 1100px) {
        top: unset;
        bottom: 80px !important;
        width: 90%;
        left: 0;
        right: 0;
        margin: auto;
    }
}

.order-form {
    width: 400px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sponsor-info {
    margin-top: 3px;
    font-size: 12px;
    text-align: right;
    color: #4b4b4b;
}

.price-info {
    margin-top: 12px;
    font-size: 12px;
    text-align: center;
    color: #4b4b4b;
}

.payment-methods {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    &__description {
        font-size: 14px;
        color: #4b4b4b;
    }
}
</style>
