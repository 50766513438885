import { getTemplate } from '@/content/products'

import {
    createCutOut,
    getCroppedImage,
    getDataUrl,
    mergeImagesWithOpacity,
    loadImage,
} from '@/utils/utils'

export async function createFinalTemplate({
    currentDrawing,
    effectStrength,
    useClippedImage,
    cropperValues,
    templatePath,
    backgroundColor,
    text,
    textColor,
    textSize,
    textFont,
    customTemplate,
}) {
    const effectImage = currentDrawing.resultImage
    const originalImage = currentDrawing.originalImage
    const cutOutMask = currentDrawing.cutoutMask

    let imageToUse = await mergeImagesWithOpacity(
        effectImage,
        originalImage,
        effectStrength,
        null
    )

    if (useClippedImage) {
        imageToUse = await createCutOut(imageToUse, cutOutMask, null)
    }

    if (cropperValues) {
        imageToUse = await getCroppedImage(imageToUse, cropperValues, 2000)
    }

    const template = getTemplate(templatePath)

    const templateFile = customTemplate || template.templateFile

    const templateImage = await loadImage(templateFile)
    const resultImage = await loadImage(imageToUse)

    const canvas = document.createElement('canvas')
    canvas.width = templateImage.width
    canvas.height = templateImage.height

    const ctx = canvas.getContext('2d')

    // Calculate the actual width, height, and bottom position of the box
    const boxWidth =
        templateImage.width * (template.drawingContainer.width / 100)
    const boxHeight =
        templateImage.height * (template.drawingContainer.height / 100)
    const boxX = (canvas.width - boxWidth) / 2
    const boxY =
        canvas.height -
        boxHeight -
        canvas.height * (template.drawingContainer.bottom / 100)

    if (!useClippedImage) {
        //TODO apply background textures
        ctx.fillStyle = backgroundColor
        ctx.fillRect(0, 0, canvas.width, canvas.height)
    } else {
        ctx.fillStyle = '#ffffff'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = backgroundColor
        ctx.fillRect(boxX, boxY, boxWidth, boxHeight)
    }

    const imageAspectRatio = resultImage.width / resultImage.height
    const boxAspectRatio = boxWidth / boxHeight

    if (imageAspectRatio > boxAspectRatio) {
        // The image is wider than the box, so we need to cover the width of the box and crop the top and bottom of the image
        const imageWidth = resultImage.height * boxAspectRatio
        const imageX = (resultImage.width - imageWidth) / 2
        ctx.drawImage(
            resultImage,
            imageX,
            0,
            imageWidth,
            resultImage.height,
            boxX,
            boxY,
            boxWidth,
            boxHeight
        )
    } else {
        // The image is taller than the box, so we need to cover the height of the box and crop the sides of the image
        const imageHeight = resultImage.width / boxAspectRatio
        const imageY = (resultImage.height - imageHeight) / 2
        ctx.drawImage(
            resultImage,
            0,
            imageY,
            resultImage.width,
            imageHeight,
            boxX,
            boxY,
            boxWidth,
            boxHeight
        )
    }

    // Draw the template on the canvas
    ctx.drawImage(templateImage, 0, 0, canvas.width, canvas.height)

    const x = canvas.width / 2
    const y = canvas.height - (template.text.bottom / 100) * canvas.height

    await drawText(ctx, text, textFont, textColor, textSize, x, y)

    const finalTemplateBlob = await new Promise((resolve) =>
        canvas.toBlob(resolve, 'image/png')
    )
    const dataUrl = await getDataUrl(finalTemplateBlob, 'image/png')
    return {
        dataUrl,
        blob: finalTemplateBlob,
    }
}

async function drawText(ctx, text, fontFamily, textColor, textSize, x, y) {
    console.log((ctx.canvas.height * textSize) / 100)
    const fontSize = (ctx.canvas.height * textSize) / 100
    // Draw the text on the canvas with the calculated font size
    ctx.font = `${fontSize}px ${fontFamily}`
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = textColor
    ctx.fillText(text, x, y)
}
