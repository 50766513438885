<template>
    <v-row v-if="level.children">
        <v-col v-if="level?.childrenLabel" cols="12">
            {{ level?.childrenLabel }}
        </v-col>
        <v-col
            cols="6"
            md="6"
            lg="4"
            v-for="levelChild in level.children"
            :key="levelChild.key"
        >
            <TextImageCard
                :selected="templatePath.includes(levelChild.key)"
                :on-select="() => setPath(levelChild.key)"
                :image="levelChild.image"
                :lazy="levelChild.lazy"
                :description="levelChild.label"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getLevel } from '@/content/products'
import TextImageCard from '@/components/TextImageCard'
export default {
    name: 'PathLevel',
    components: { TextImageCard },
    props: {
        path: {
            type: Array,
            require: true,
        },
    },
    computed: {
        ...mapGetters(['customization', 'templatePath']),
        level() {
            return getLevel(this.path)
        },
    },
    methods: {
        ...mapMutations(['setTemplatePath']),
        setPath(key) {
            const defaultPath = this.level?.children[key]?.defaultPath || []
            const newPath = [...this.path, key, ...defaultPath]
            this.setTemplatePath(newPath)
        },
    },
}
</script>

<style scoped></style>
